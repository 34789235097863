<template>
    <div class="min-h-screen flex flex-col w-full pb-8 px-3 md:px-0">
        <p class="mt-3 text-xl leading-8 font-semibold tracking-tight text-gray-900 pb-4">
            Rapor Peserta Didik
        </p>

        <!-- <div class="fixed bottom-20 left-0 right-0 z-20 text-center" v-if="isLoading == false">
            <a :href="blobURL" target="_system" 
                class="transition-all py-3 px-4 border border-transparent text-sm font-lg rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg> Unduh Rapor
            </a>
        </div> -->

        <iframe width="100%" :src="`${blobURL}`" class="flex-grow rounded-lg" v-if="isLoading == false" />
        <loading-modal :isShow="isLoading"/>
    </div>
</template>
<script>
    import LoadingModal from '../../components/LoadingModal.vue';
    import { errorHandler } from '../../helpers/Tools';
    export default {
        name: 'RaporPdf',
        components : {
            LoadingModal
        },
        data (){
            return {
                isLoading: true,
                blobURL  : null
            }
        },
        mounted() {
            this.getRaporView();
		},
		methods : {
            
            async getRaporView() {
                this.isLoading = true;
                try {
                    let res    = await this.$store.dispatch("getRapor", {
                        singkatan_kategori : this.$route.params.singkatan_kategori
                    });
                    let result = res.data;
                    let blob   = new Blob([result], {
                        type : 'application/pdf'
                    });
                    this.blobURL = window.URL.createObjectURL(blob);
                    this.isLoading = false;
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

        }

    }
</script>
